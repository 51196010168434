<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <div class="cloud-wrapper">
            <v-card width="350">
                <v-card-title class="justify-center align-center nuvolos-header">
                    <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
                </v-card-title>

                <h2 class="secondary--text text-center text-h6 mt-5">Preparing your workspace</h2>

                <v-card-text>
                    This might take a few seconds.
                    <br />
                    Please bear with us until we're done.
                </v-card-text>
                <v-card-text class="text-center pb-16">
                    <dashboard-error v-if="error" :msg="errorMsg" />
                    <template v-else>
                        <p>{{ signupFlowProgressText }}</p>
                        <v-progress-linear stream buffer-value="0" v-model="progress" color="primary" rounded height="6"></v-progress-linear>
                    </template>
                    <v-btn v-if="nuvolosError && error.response.data.code === 'user_already_exists'" :to="{ name: 'login' }" class="mt-4">go to login</v-btn>
                </v-card-text>
            </v-card>

            <img src="@/assets/cloud-1.svg" class="cloud cloud-1" />
            <img src="@/assets/cloud-3.svg" class="cloud cloud-3" />
        </div>
    </div>
</template>

<script>
const DashboardError = () => import('@/components/DashboardError.vue')

export default {
    name: 'GoogleSignin',
    mixins: [],
    components: { DashboardError },
    data() {
        return {
            trialSid: null,
            signupFlowProgressText: '',
            error: null,
            errorMsg: '',
            invitationId: '',
            progress: 0,
            overviewURI: '',
            dashboardOid: ''
        }
    },
    created() {},
    methods: {
        async signUpFlow() {
            this.signupFlowProgressText = 'Fetching information.'
            const step1 = await this.fetchTrialData()
            if (this.$isError(step1)) return
            this.progress = 25

            this.signupFlowProgressText = 'Creating workspace.'
            const step2 = await this.createTrialSpace()
            if (this.$isError(step2)) return
            this.progress = 50

            this.signupFlowProgressText = 'Creating user account.'
            const step3 = await this.createUser()
            if (this.$isError(step3)) return
            this.progress = 75

            this.signupFlowProgressText = 'Finalizing configuration.'
            await this.acceptInvitation()
            this.progress = 100

            this.signupFlowProgressText = 'Thank you for your patience!'
            await new Promise(resolve => setTimeout(resolve, 800))
            this.$router.push({ name: 'home-dashboard', params: { oid: this.dashboardOid }, query: { invitation_target: this.overviewURI } })
        },
        async fetchTrialData() {
            try {
                const { data } = await this.$axios.get(`/trial/describe/${this.$route.params.token}`)
                this.trialSid = data.from_sid
                return data
            } catch (error) {
                console.log(error)
                this.error = error
                return error
            }
        },
        async createTrialSpace() {
            try {
                const { data } = await this.$axios.post(`/trial/start`, {
                    email: this.$auth.user.email,
                    g_recaptcha_response: '',
                    from_sid: this.trialSid,
                    long_id: this.$auth.user.email,
                    send_invitation: true
                })
                this.invitationId = data.token
                return data
            } catch (error) {
                console.log(error)
                if (error?.response?.data?.code === 'user_already_exists') {
                    this.errorMsg = 'A Nuvolos account with this email address already exists, please log in instead.'
                }
                if (error?.response?.data?.code === 'trial_already_started') {
                    this.errorMsg =
                        'A trial with this email has already been started recently. Please contact <br /><a href="mailto:support@nuvolos.cloud" class="font-weight-bold warning--text">support@nuvolos.cloud</a><br /> if you wish to resume your trial.'
                }
                this.error = error
                return error
            }
        },
        async createUser() {
            try {
                const { data } = await this.$axios.post(`/users/register_with_invitation/google-oauth2/${this.invitationId}`, {
                    email: this.$auth.user.email,
                    family_name: this.$auth.user.family_name || 'Anonymus',
                    given_name: this.$auth.user.given_name || 'User'
                })
                this.$store.dispatch('getUserInfo')
                return data
            } catch (error) {
                console.log(error)
                this.error = error
                return error
            }
        },
        async acceptInvitation() {
            try {
                const { data } = await this.$axios.get(`/invitations/accept/${this.invitationId}`)
                this.overviewURI = data.landing_page.split('nuvolos.cloud').pop()
                this.dashboardOid = this.overviewURI.split('/')[2]
                this.$store.dispatch('fetchUserOrgs')
                return data
            } catch (error) {
                console.log(error)
                this.error = error
                return error
            }
        }
    },
    computed: {
        nuvolosError() {
            return this.error?.response?.data?.code
        }
    },
    watch: {
        '$auth.isAuthenticated': {
            handler(authenticated) {
                if (authenticated) {
                    this.signUpFlow()
                } else {
                    // not authenticated handling
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
